
import { defineComponent, PropType } from 'vue';
import Card from '@/components/global/Card.vue';
import SmallButton from '@/components/global/SmallButton.vue';

export default defineComponent({
  components: {
    Card, SmallButton,
  },
  props: {
    transcriptionInfo: String,
    selectedWord: {
      type: Number,
      required: false,
    },
    analyses: Array as PropType<Array<string>>,
  },
});
