import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "lg:flex"
}
const _hoisted_4 = { class: "lg:pl-3 flex-grow w-full" }
const _hoisted_5 = { class: "text-2xl p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_VerseBar = _resolveComponent("VerseBar")!
  const _component_CairoQuran = _resolveComponent("CairoQuran")!
  const _component_ArabicText = _resolveComponent("ArabicText")!
  const _component_Transcription = _resolveComponent("Transcription")!
  const _component_WordAnalysis = _resolveComponent("WordAnalysis")!
  const _component_Card = _resolveComponent("Card")!
  const _component_HowToQuote = _resolveComponent("HowToQuote")!
  const _component_RemoteContent = _resolveComponent("RemoteContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionHeader, {
      infoText: _ctx.printWeb.payload?.introduction_general
    }, {
      title: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: _ctx.$t('print_edition.page_title')
        }, null, 8, _hoisted_1)
      ]),
      subtitle: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: `${_ctx.$t('global.sura')} ${_ctx.$route.params.sura} ${_ctx.$t('global.verse')}
  ${_ctx.$route.params.verse}`
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["infoText"]),
    _createVNode(_component_VerseBar, {
      overviewText: _ctx.$t('navigation.link_overview_print'),
      middleLink: { name: 'PrintEditionOverview' }
    }, null, 8, ["overviewText"]),
    _createVNode(_component_RemoteContent, { data: _ctx.printData }, {
      default: _withCtx(() => [
        (_ctx.isLoaded(_ctx.printData))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_CairoQuran),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_ArabicText, {
                  arabicTextInfo: _ctx.printWeb.payload?.arabic_text_info,
                  arabicVerse: _ctx.printData.payload.arabic_text,
                  selectedWord: _ctx.selectedWord,
                  onWordSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedWord = $event))
                }, null, 8, ["arabicTextInfo", "arabicVerse", "selectedWord"]),
                _createVNode(_component_Transcription, {
                  transcriptionInfo: _ctx.printWeb.payload?.transcription_info,
                  transcription: _ctx.printData.payload.transcription_text,
                  selectedWord: _ctx.selectedWord,
                  onWordSelected: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedWord = $event))
                }, null, 8, ["transcriptionInfo", "transcription", "selectedWord"]),
                _createVNode(_component_WordAnalysis, {
                  transcriptionInfo: _ctx.printWeb.payload?.morphology_info,
                  selectedWord: _ctx.selectedWord,
                  analyses: _ctx.printData.payload.transcription_analysis[_ctx.selectedWord]
                }, null, 8, ["transcriptionInfo", "selectedWord", "analyses"]),
                _createVNode(_component_Card, {
                  title: _ctx.$t('print_edition.translation_header'),
                  informationText: _ctx.printWeb.payload?.translation_info
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.printData.payload.translation), 1)
                  ]),
                  _: 1
                }, 8, ["title", "informationText"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.isLoaded(_ctx.printWeb))
          ? (_openBlock(), _createBlock(_component_HowToQuote, {
              key: 1,
              citation: _ctx.printWeb.payload.how_to_cite
            }, null, 8, ["citation"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["data"])
  ], 64))
}